@import '../fonts';

@mixin alerts-material-overrides() {
    .set-alerts-notifications-frequency {
        .mat-form-field-wrapper {
            padding: 0;
        }

        .mat-select-value, .mat-select-arrow, .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
            color: #fff;
        }

        .mat-select-value {
            width: auto;
            font-size: 13px;
        }

        .mat-form-field-outline.mat-form-field-outline-thick {
            display: none;
        }

        .mat-form-field-flex {
            padding: 0 !important;
            margin-top: 0 !important;
        }

        .mat-form-field-appearance-outline .mat-form-field-outline {
            display: none;
        }

        .mat-select-arrow-wrapper {
            transform: unset !important;
        }

        .mat-form-field-infix {
            padding: 0;
            border-top: none;
        }

        .mat-select-trigger {
            height: 30px;
            justify-content: center;
            gap: 10px;
        }
    }
    .set-alerts-notifications-alert-toggles, .basic-toggle-container {
        .mat-slide-toggle-label {
            justify-content: center;
        }

        .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
            background-color: #62C37D;
        }

        .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
            background-color: #C9E8D2;
        }

        .mat-ripple {
            display: none;
        }
    }

    .set-alerts-notifications-frequency-option {
        .mat-option-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}