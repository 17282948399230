@import '/src/assets/scss/variables.scss';

@mixin subscription-page-material-overrides() {
    app-unsubscribe-pipline-widget {
        .mat-radio-outer-circle {
            border-color: $green !important;
        }

        .mat-radio-inner-circle, .mat-ripple-element {
            background-color: $green !important;
        }

        .unsubscribe-pipline-widget-step-schedule-call {
            .mat-checkbox-inner-container {
                width: 20px;
                height: 20px;
            }

            .mat-checkbox-frame {
                border-radius: 5px;
            }

            .mat-checkbox-checked .mat-checkbox-background {
                background-color: $green !important;
                border-radius: 5px;
            }

            .mat-checkbox-disabled .mat-checkbox-label {
                color: inherit;
            }
        }

        .mat-radio-label-content, .mat-checkbox-label {
            white-space: break-spaces;
        }
    }
}