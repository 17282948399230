@import '../fonts';
@import '../variables.scss';
@import './forms-material-overrides';
@import './money-flow-material-overrides';
@import './portfolio-material-overrides';
@import './portfolio-upside-calculator-material-overrides';
@import './sector-trends-material-overrides';
@import './sector-visuals-material-overrides';
@import './overview-diagram-material-overrides';
@import './mat-dialog-material-overrides';
@import './tv-diagram-material-overrides';
@import './portfolio-risk-heatmap';
@import './portfolio-money-flow';
@import './auth-form-material-overrides';
@import './alerts-material-overrides';
@import './home-material-overrides';
@import './footer-material-overrides';
@import './subscription-page-material-overrides';

@mixin material-overrides() {

  .mat-expansion-panel {
    box-shadow: none !important;
    border-top: 1px solid #EAEAEA;

    @media screen and (max-width: $mobile-trigger) {
      padding: 0;
    }

    mat-panel-description {
      @media screen and (max-width: $mobile-trigger) {
        margin-right: 0;
      }
    }

    mat-expansion-panel-header {
      background: #fff !important;
      padding: 0 50px 0 30px;
      height: 80px;

      @media screen and (max-width: $mobile-trigger) {
        padding: 0 0 0 4px;
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        @media screen and (max-width: $mobile-trigger) {
          padding: 0;
        }
      }
    }
  }

  .mat-expanded mat-expansion-panel-header .mat-expansion-indicator::after {
    color: #5DB977;
  }

  .trend-grid, .heatmap-grid {
    opacity: 0.1;

    .domain {
      display: none;
    }
  }

  app-trend, app-fundamentals-dialog {

    mat-checkbox {
      .mat-checkbox-ripple {
        display: none !important;
      }

      .mat-checkbox-background {
        background-color: unset !important;
        animation: unset !important;
      }

      .mat-checkbox-checkmark path {
        stroke: #B9B7B7 !important;
      }

      .mat-checkbox-inner-container {
        width: 18px;
        height: 18px;
      }

      .mat-checkbox-frame {
        border-width: 1px;
      }
    }
  }

  mat-dialog-container {
    border-radius: 10px !important;

    @media screen and (max-width: $mobile-trigger) {
      border-radius: none !important;
    }
  }

  .gradient-hover {
    position: relative;
    width: 20px;
    height: 20px;

    &::after {
      content: none;
      position: absolute;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background: linear-gradient(#FF4200, #FFB800);
      z-index: -1;
      left: -10%;
      bottom: 10%;
    }
  }

  .gradient-hover:hover {
    color: #fff;
    cursor: pointer;
    & > * {
      color: #fff;
    }

    &::after {
      content: '';

      @media screen and (max-width: $mobile-trigger) {
        content: none;
      }
    }
  }

  svg {
    .domain {
      display: none;
    }

    .tick {
      font-weight: 600;
      font-size: 11px;

      line {
        display: none;
      }
    }

    .bar-chart-content {
      font-weight: 300;
    }
  }

  app-fundamental-filters {
    mat-radio-button {
      .mat-radio-inner-circle {
        background-color: #C8E7D4 !important;
      }

      .mat-radio-outer-circle {
        border: none !important;
        background-color: #62C37D;
      }

      .mat-ripple-element {
        display: none !important;
      }
    }
  }

  app-fundamentals-and-estimates-income-statement-compare-ratio-dialog {
    mat-radio-button {
      .mat-radio-inner-circle {
        background-color: #C5C5C5 !important;
        opacity: 1 !important;
        transform: scale(0.5) !important;
      }

      .mat-radio-outer-circle {
        border: none !important;
        background-color: #EEEEEE
      }

      .mat-ripple-element {
        display: none !important;
      }

      &.mat-radio-checked {
        .mat-radio-inner-circle {
          background-color: #C8E7D4 !important;
        } 

        .mat-radio-outer-circle {
          background-color: #62C37D !important;
        }
      }
    }
  }

  app-scrollable {
    .simplebar-content-wrapper {
      border: none;
      outline: none;
    }
  }

  .diagram-section {
    display: flex;
    position: absolute;
    font-weight: 600;
    color: #B0190C;
    width: 5px;
    transform: translateY(-50%);

    @media screen and (max-width: $mobile-trigger) {
      font-size: 12px;
    }

    &-text {
      min-width: 125px;
      line-height: 16px;

      @media screen and (max-width: $mobile-trigger) {
        min-width: 75px;
      }
    }
  }

  .dialog-mobile {
    .mat-dialog-container {
      box-shadow: none !important;
      border-radius: none !important;
      width: 100vw;
    }
  }

  .mobile {
    @media  screen and (min-width: $breakpoint-min-md) {
      display: none !important;
    }
  }

  .desktop {
    @media screen and (max-width: $breakpoint-max-sm) {
      display: none !important;
    }
  }

  .last-sector-updates-right-diagram-graph svg {
    width: 100%;
    @media screen and (max-width: $breakpoint-max-sm) {
      height: 190px;
    }
  }

  .trial-banner-text .accent {
      color: #EB5757;
  }

  .fundamentals-table-dialog-backdrop, .cdk-overlay-dark-backdrop {
    background: transparent;
    backdrop-filter: blur(3px);
  }

  html {
    top: 0 !important;
  }

  .cdk-global-scrollblock {
    position: static;
    overflow-y: visible;
  }

  .mat-dialog-container {
    @media (max-width: 1023.99) {
      max-height: calc(100vh - 140px) !important;
    }

    @media (max-width: 768px) {
      max-height: unset !important;
      background-color: transparent;
    }
  }

  .money-flow-tabs, .fundamentals-and-estimates-tabs {

    mat-tab-header {
      border-radius: 10px;
    }

    .mat-tab-labels {
      background-color: #fff;
      padding: 5px;
      & > * {
        flex: 1;
        font-size: 13px;
        color: #000;
        opacity: 1;

        @media (max-width: 770px) {
          min-width: 75px;
          min-height: 48px;
          height: auto;
        }

        @media (max-width: 460px) {
          padding: 0 12px;
        }

        @media (max-width: 460px) {
          padding: 0 5px;
        }

        .mat-tab-label-content {
          @media (max-width: 620px) {
            white-space: break-spaces;
            font-size: 13px;
            line-height: 15px;
          }
        }

        &.mat-tab-label-active {
          color: #fff;
          background-color: #62C37D !important;
          border-radius: 5px;
        }
      }
    }

    mat-ink-bar {
      display: none;
    }
  }
}

@include forms-material-overrides();
@include money-flow-material-overrides();
@include portfolio-material-overrides();
@include portfolio-upside-calculator-material-overrides();
@include sector-trends-material-overrides();
@include sector-visuals-material-overrides();
@include mat-dialog-material-overrides();
@include overview-diagram-material-overrides();
@include tv-diagram-material-overrides();
@include portfolio-risk-heatmap-material-overrides();
@include portfolio-money-flow-material-overrides();
@include auth-form-material-overrides();
@include alerts-material-overrides();
@include home-material-overrides();
@include footer-material-overrides();
@include subscription-page-material-overrides();