/* https://codepen.io/mildrenben/pen/bdGdOb */

$tabs-underline-height: 4px;

.tabs {
  @apply
  relative
  w-full;

  &:before {
    @apply
    absolute
    left-0
    bottom-0
    border-b-4
    border-default-neutral-1
    w-full;

    content: '';
  }

  li {
    a.tab {
      @apply
      flex
      items-center
      justify-center
      border-b-4
      border-default-neutral-1
      hover:border-active-neutral-1
      rounded-t-lg
      text-interface-title-5-semibold
      md:text-interface-title-4-semibold
      text-default-neutral-3
      px-4 md:px-7
      pt-3 md:pt-5
      pb-2 md:pb-4
      duration-300;

      &:hover {
        @apply
        border-active-neutral-1
        text-active-neutral-3;
      }
    }
  }

  li.active {
    a.tab {
      @apply
      bg-primary-1
      text-default-primary-2
      pointer-events-none;
    }
  }

  .underline {
    @apply absolute bg-default-primary-2 transition-all duration-300 ease-out;

    height: $tabs-underline-height;
    width: #{'var(--active-tab-width)'};
    top: calc(100% - #{$tabs-underline-height});
    left: #{'var(--active-tab-left)'};
  }
}
