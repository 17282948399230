@import '../fonts';

@mixin portfolio-risk-heatmap-material-overrides() {
  app-portfolio-risk-heatmap-cell-dialog {
    mat-tab-header {
      border-bottom: none !important;
    }

    mat-ink-bar {
      display: none;
    }

    .mat-tab-labels {
      display: inline-flex;
      padding: 3px;
      background-color: #F3F8FE;
      border-radius: 5px;
      border: 1px solid #EAEAEA;
      margin-left: 10px;

      .mat-tab-label {
        background-color: transparent;
        color: #000;
        opacity: 1;
        

        &.mat-tab-label-active {
          background-color: #62C37D !important;
          color: #fff;
        }

        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}