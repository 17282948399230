@import '../fonts';

@mixin portfolio-upside-calculator-material-overrides() {
  tooltip.portfolio-upside-calculator-tooltip {
    padding: 15px;
    color: #5F6388 !important;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }
}
