@tailwind utilities;

@layer utilities {
  .text-interface-title-1-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-title-1-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-title-1-light {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 300;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-title-2-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-title-2-light {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-title-3-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-title-3-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-title-4-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-title-4-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-title-5-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-title-5-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-title-6-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-title-6-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-body-1-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-body-1-light {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-body-2-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-body-2-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-control-1-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-control-1-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-control-2-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-control-2-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-control-3-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-control-3-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-control-4-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-control-4-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-control-5-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-control-5-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-caption-1-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-caption-2-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-caption-2-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-caption-1-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-caption-3-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-caption-3-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-title-1-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-title-1-semibold {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-control-1-regular {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-control-1-bold {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-indent: 0;
  }

  .text-chart-control-2-regular {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-control-3-regular {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-indent: 0;
  }

  .text-chart-control-3-bold {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-indent: 0;
  }

  .text-chart-caption-1-regular {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-caption-1-bold {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-caption-2-regular {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-caption-2-bold {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-caption-3-regular {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-caption-3-bold {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-caption-4-regular {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-control-2-bold {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-chart-caption-4-bold {
    font-family: "B612 Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-display-1-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 92px;
    line-height: 88px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-display-1-thin {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 200;
    font-size: 92px;
    line-height: 88px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-display-2-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 76px;
    line-height: 76px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-display-2-thin {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 200;
    font-size: 76px;
    line-height: 76px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-display-3-regular {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 64px;
    letter-spacing: 0;
    text-indent: 0;
  }

  .text-interface-display-3-light {
    font-family: "Averta CY";
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 64px;
    letter-spacing: 0;
    text-indent: 0;
  }
}
