@import url('https://fonts.googleapis.com/css2?family=B612+Mono:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

// ------ KW UI Kit START

@import "styles/base/animation";
@import "styles/base/borders";
@import "styles/base/colors";
@import "styles/base/gradients";
@import "styles/base/shadows";
@import "styles/base/typography";

@import "styles/components/buttons";
@import "styles/components/inputs";
@import "styles/components/selects";
@import "styles/components/scrollbar";
@import "styles/components/tabs";

// ------ KW UI Kit END

.cdk-overlay-pane.scroll-y .mat-dialog-container {
  overflow: hidden auto;
}
