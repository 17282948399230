.kw-form-control {
  @apply relative
  flex
  items-center
  justify-between
  w-full
  bg-default-neutral-4
  transition-colors
  duration-300;

  // Default for every icon

  .kw-form-control-icon {
    @apply flex;

    [id$='error'] {
      @apply hidden text-default-error-1 cursor-pointer;
    }
  }

  // Default for every input

  .kw-form-control-input {
    @apply w-full
    border-0
    outline
    outline-0
    shadow-none
    overflow-ellipsis
    bg-transparent
    text-default-neutral-1
    placeholder:text-low;

    &:focus {
      @apply outline outline-0;
    }

    &:disabled {
      @apply text-low select-none cursor-not-allowed;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition-property: background-color;
      transition-delay: 1000000000s;
    }
  }

  // STATE

  &:focus-within {
    @apply bg-default-secondary-1;
  }

  .kw-form-control-input.ng-touched.ng-invalid {
    @apply bg-default-tertiary-1;

    & ~ i {
      @apply bg-default-tertiary-1;

      [id$='error'] {
        @apply block;
      }
    }
  }

  &:disabled {
    .kw-form-control-icon {
      @apply text-low select-none cursor-not-allowed;

      // TODO: Maybe hide error icon?

      [id$='error'] {
        @apply text-low;
      }
    }
  }

  // SIZE

  &.kw-form-control-md {
    @apply rounded-[8px];

    .kw-form-control-icon {
      @apply gap-[8px] rounded-r-[8px] p-[6px];
    }

    .kw-form-control-input {
      @apply text-interface-control-2-regular rounded-l-[8px] p-[8px] rounded-r-none;

      // No icons at all (except hidden error icon)

      &:last-child:not(:first-child) {
        @apply pr-[8px];
      }

      &:first-child:not(:last-child) {
        @apply pl-[8px];
      }
    }

    // Placeholder

    .kw-form-control-float-placeholder {
      @apply text-interface-control-1-regular;

      .kw-form-control-input {
        @apply pt-[16px] pb-[0];
      }

      // No icons at all (except hidden error icon)

      &:last-child:not(:first-child) {
        .kw-form-control-placeholder {
          @apply right-[8px];
        }
      }

      &:first-child:not(:last-child) {
        .kw-form-control-placeholder {
          @apply left-[8px];
        }
      }
    }
  }

  &.kw-form-control-lg {
    @apply rounded-[8px];

    .kw-form-control-icon {
      @apply gap-[8px] rounded-r-[8px] p-[12px];
    }

    .kw-form-control-input {
      @apply text-interface-control-1-regular rounded-l-[8px] p-[12px] rounded-r-none;

      // No icons at all (except hidden error icon)

      &:last-child:not(:first-child) {
        @apply pr-[8px];
      }

      &:first-child:not(:last-child) {
        @apply pl-[8px];
      }
    }

    // Placeholder

    .kw-form-control-float-placeholder {
      @apply text-interface-control-1-regular;

      .kw-form-control-input {
        @apply pt-[16px] pb-[0];
      }

      // No icons at all (except hidden error icon)

      &:last-child:not(:first-child) {
        .kw-form-control-placeholder {
          @apply right-[8px];
        }
      }

      &:first-child:not(:last-child) {
        .kw-form-control-placeholder {
          @apply left-[8px];
        }
      }
    }
  }

  // Placeholder

  .kw-form-control-float-placeholder {
    @apply relative w-full;

    .kw-form-control-placeholder {
      @apply text-low absolute left-0 top-1/2 -translate-y-[18px] scale-[0.66] transition-transform duration-300 origin-top-left pointer-events-none;
    }

    .kw-form-control-input {
      @apply placeholder:text-transparent;

      &:placeholder-shown:not(:focus) {
        & ~ .kw-form-control-placeholder {
          @apply -translate-y-1/2 scale-100;
        }
      }
    }
  }
}

.kw-form-control-checkbox {
  @apply relative flex items-start justify-start gap-[6px];

  // Default for every input

  .kw-form-control-input {
    @apply hidden;

    &:checked ~ .kw-form-control-checkbox-mark {
      @apply border-default-primary-1 bg-default-primary-2;

      // Mark

      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M12.4666 4.79998C12.1999 4.53331 11.7999 4.53331 11.5333 4.79998L6.53325 9.79998L4.46659 7.73331C4.19992 7.46664 3.79992 7.46664 3.53325 7.73331C3.26659 7.99998 3.26659 8.39998 3.53325 8.66664L6.06659 11.2C6.19992 11.3333 6.33325 11.4 6.53325 11.4C6.73325 11.4 6.86659 11.3333 6.99992 11.2L12.4666 5.73331C12.7333 5.46664 12.7333 5.06664 12.4666 4.79998Z" fill="white"/></svg>');
    }
  }

  // Default for every icon

  .kw-form-control-checkbox-mark {
    @apply min-w-[20px] min-h-[20px] border-2 border-default-neutral-2 rounded-[4px] m-[2px];
  }

  // Default for every text

  .kw-form-control-checkbox-text {
    @apply select-none cursor-pointer my-[2px];
  }

  // STATE

  &:hover {
    .kw-form-control-input {
      &:checked ~ .kw-form-control-checkbox-mark {
        @apply border-active-primary-1 bg-active-primary-2;
      }
    }

    .kw-form-control-checkbox-mark {
      @apply border-active-neutral-2;
    }
  }

  .kw-form-control-input.ng-touched.ng-invalid {
    & ~ .kw-form-control-checkbox-mark {
      @apply border-default-error-1;
    }

    & ~ .kw-form-control-checkbox-text {
      @apply text-default-error-1;
    }
  }

  &:disabled {
    .kw-form-control-input {
      &:checked ~ .kw-form-control-checkbox-mark {
        @apply border-default-neutral-1 bg-transparent;

        // Mark

        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M12.4666 4.79998C12.1999 4.53331 11.7999 4.53331 11.5333 4.79998L6.53325 9.79998L4.46659 7.73331C4.19992 7.46664 3.79992 7.46664 3.53325 7.73331C3.26659 7.99998 3.26659 8.39998 3.53325 8.66664L6.06659 11.2C6.19992 11.3333 6.33325 11.4 6.53325 11.4C6.73325 11.4 6.86659 11.3333 6.99992 11.2L12.4666 5.73331C12.7333 5.46664 12.7333 5.06664 12.4666 4.79998Z" fill="%23E4EAF1"/></svg>');
      }
    }

    .kw-form-control-checkbox-mark {
      @apply cursor-not-allowed border-default-neutral-1;
    }

    .kw-form-control-checkbox-text {
      @apply cursor-not-allowed;
    }
  }
}

.kw-form-control-switch {
  @apply relative;

  label {
    @apply relative
    top-1
    cursor-pointer;
  }

  .kw-form-control-input {
    @apply hidden;

    &:checked ~ label .kw-form-control-checkbox-mark {
      @apply left-6
      bg-default-primary-2;
    }

    &:checked ~ label .kw-form-control-checkbox-bg {
      @apply bg-default-primary-1;
    }
  }

  .kw-form-control-checkbox-bg {
    @apply w-[48px]
    h-[16px]
    rounded-full
    bg-active-neutral-1
    duration-300;
  }

  .kw-form-control-checkbox-mark {
    @apply absolute
    -top-1
    left-0
    w-[24px]
    h-[24px]
    bg-default-neutral-2
    rounded-full
    duration-300;

    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  }
}

/* радио в виде ползунка */
.kw-form-control-slider-radio-list {
  @apply relative
  flex
  flex-shrink-0
  gap-1
  bg-default-neutral-2
  p-1
  rounded-full;

  fieldset {
    @apply flex
    px-1.5 md:px-3
    py-1 md:py-2
    rounded-full
    duration-300;

    label {
      @apply relative
      z-[2]
      px-0.5 md:px-1
      cursor-pointer
      duration-700
      text-interface-control-3-semibold;
    }

    &:disabled {
      @apply opacity-20;

      label {
        @apply cursor-not-allowed;
      }
    }
  }

  fieldset:hover {
    @apply bg-[#fff];
  }

  .slider {
    @apply absolute
    top-1
    h-6 md:h-8
    rounded-full
    bg-default-neutral-1
    duration-500;
  }
}

.kw-form-control-radio {
  @apply relative flex items-start justify-start gap-[6px];

  .kw-form-control-input {
    @apply hidden;
  }

  .kw-form-control-radio-mark {
    @apply min-w-[20px] min-h-[20px] border border-default-neutral-2 rounded-full m-[2px];

    background-repeat: no-repeat;
    background-position: center;
  }

  .kw-form-control-input {
    &:checked ~ .kw-form-control-radio-mark {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='6' fill='%2362C37D'/%3E%3C/svg%3E");
    }
  }

  .kw-form-control-radio-text {
    @apply select-none cursor-pointer my-[2px];
  }

  &:hover {
    .kw-form-control-input {
      &:checked ~ .kw-form-control-radio-mark {
        @apply border-active-primary-1;
      }
    }

    .kw-form-control-radio-mark {
      @apply border-active-neutral-2;
    }
  }

  &:disabled {
    .kw-form-control-input {
      &:checked ~ .kw-form-control-radio-mark {
        @apply border-default-neutral-1 bg-transparent;
      }
    }

    .kw-form-control-radio-mark {
      @apply cursor-not-allowed border-default-neutral-1;
    }

    .kw-form-control-radio-text {
      @apply cursor-not-allowed;
    }
  }
}

.kw-form-control-radio-special {
  @apply relative
  flex
  items-start
  justify-start
  gap-[6px]
  p-3
  rounded-lg
  bg-default-neutral-2
  cursor-pointer
  duration-300;

  .kw-form-control-input {
    @apply hidden;
  }

  .kw-form-control-radio-mark {
    @apply min-w-[20px]
    min-h-[20px]
    border-2
    border-default-neutral-2
    rounded-full
    m-[2px]
    duration-300;

    background-repeat: no-repeat;
    background-position: center;
  }

  .kw-form-control-input {
    &:checked ~ .kw-form-control-radio-mark {
      @apply border-default-primary-1;

      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Ccircle cx='10' cy='10' r='6' fill='%2362C37D'/%3E%3C/svg%3E%0A");
    }
  }

  .kw-form-control-radio-text {
    @apply select-none cursor-pointer my-[2px];
  }

  &:hover:not(.checked) {
    @apply bg-active-neutral-2;

    .kw-form-control-input {
      &:checked ~ .kw-form-control-radio-mark {
        @apply border-active-primary-1;
      }
    }

    .kw-form-control-radio-mark {
      @apply border-active-neutral-2;
    }
  }

  &.checked {
    @apply bg-default-primary-1;

    &:hover {
      @apply bg-active-primary-1;

      .kw-form-control-radio-mark {
        @apply border-active-primary-1;

        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Ccircle cx='10' cy='10' r='6' fill='%234E9C64'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &:disabled {
    .kw-form-control-input {
      &:checked ~ .kw-form-control-radio-mark {
        @apply border-default-neutral-1 bg-transparent;
      }
    }

    .kw-form-control-radio-mark {
      @apply cursor-not-allowed border-default-neutral-1;
    }

    .kw-form-control-radio-text {
      @apply cursor-not-allowed;
    }
  }
}

.kw-form-control-label-scaling {
  @extend .kw-form-control;

  .kw-form-control-floating-label {
    @apply absolute duration-200 text-interface-control-1-regular text-low;
    top: 12px;
    left: 12px;
  }

  &:has(.kw-form-control-input:focus),
  &:has(.kw-form-control-input:not(:placeholder-shown)) {
    .kw-form-control-floating-label {
      @apply text-interface-control-4-regular;
      top: 4px;
    }

    .kw-form-control-input {
      padding-top: 18px;
      padding-bottom: 6px;
    }
  }
}

// INPUT NUMBER

input.kw-number-control::-webkit-outer-spin-button,
input.kw-number-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input.kw-number-control[type='number'] {
  -moz-appearance: textfield;
}
