@import '../fonts';
@import '../variables';

@mixin money-flow-material-overrides() {

  .money-flow-head-watchlist-heatmap {
    .mat-form-field-outline {
      color: #62C37D !important;
    }
  }

  .money-flow-filters {
    
    .mat-form-field-outline {
      background: rgba(116, 116, 128, 0.08);
      border-radius: 8px;

      & > * {
        border: none !important;
      }
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-outline-gap {
      border-top-color: inherit;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
      transform: translateY(-10px) scale(0.75);
    }
    
  }

  .money-flow-filter-option {
    .mat-option-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .money-flow-filter-range, .money-flow-filter-expiration-date {
    @media (max-width: $breakpoint-max-sm) {
      min-width: unset !important;
      position: absolute;
      width: 225px;
      top: 50px;
    }

    mat-option {
        cursor: pointer !important;
    }
  }

  .money-flow-filter-range {
    @media (max-width: $breakpoint-max-sm) {
      left: 60px;
    }
  } 

  .money-flow-filter-expiration-date {
    @media (max-width: $breakpoint-max-sm) {
      right: -20px;
    }
  }

  .money-flow-insiders-filter-checkbox {
    .mat-checkbox-frame {
      border: none;
    }

    .mat-focus-indicator {
      display: none;
    }

    .mat-checkbox-background {
      background-color: transparent !important;
    }
  }

  app-money-flow-open-interest-strike-dialog .money-flow-open-interest-table-row-value {
    width: 80px !important;
  }

  .money-flow-open-interest-strike-dialog {
    @media (max-width: 1000px) {
      max-width: calc(100vw - 20px) !important;
    }

    .money-flow-open-interest-table-body-container {
      max-height: calc(100vh - 200px);
    }

    mat-dialog-container.mat-dialog-container {
      overflow: hidden;
      box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
                  0px 24px 38px 3px rgb(0 0 0 / 14%),
                  0px 9px 46px 8px rgb(0 0 0 / 12%);
      max-height: inherit !important;
      @media (max-width: 768px) {
        padding: 0;
        app-money-flow-open-interest-strike-dialog {
          border-radius: 0;
        }
      }
    }
  }

  .money-flow-key-levels-diagram-month-data {
    svg {
      width: 250px;
    }
  }

  .money-flow-head-trade-plan-diagram app-money-flow-key-levels-diagram {
    .money-flow-key-levels-diagram-top {
      display: flex;
      padding-top: 20px;
    }

    .money-flow-key-levels-diagram-show-last-month-container,
    .money-flow-key-levels-diagram-show-next-month-container {
      display: none;
    }

    .money-flow-key-levels-diagram-data {
      max-width: 700px;

      @media (max-width: 870px) {
        max-width: 70vw;
      }
    }
  }

  .money-flow-page-tabs .mat-tab-header {
    width: 800px;
    max-width: 100%;

    @media (max-width: 900px) {
      width: 100%;
    }
  }
}
