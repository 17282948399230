@import '../fonts';

@mixin sector-visuals-material-overrides() {
  app-sector-visuals {
    .sector-visuals-link {
      display: flex;
      align-items: center;

      &-text {
        margin-left: 10px;
      }
    }

    .diagram-sector {
      display: none;
    }
  }

  .sector-visuals-portfolio-container .sector-visuals-card {
    padding: 30px 60px !important;
  }
}
