@layer utilities {
	.border-dashed-horizon {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='238' height='2' viewBox='0 0 238 2' fill='none'%3E%3Cpath d='M0.667969 1H238.001' stroke='%23393939' stroke-width='2' stroke-linejoin='round' stroke-dasharray='4 8'/%3E%3C/svg%3E");
	}

  .border-dashed-horizon-8-8 {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1192' height='2' viewBox='0 0 1192 2' fill='none'%3E%3Cpath d='M0 1H1192' stroke='%237E8CA0' stroke-dasharray='8 8'/%3E%3C/svg%3E");
  }

	.border-dashed-vertical {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2' height='38' viewBox='0 0 2 38' fill='none'%3E%3Cpath d='M1 0L0.999998 38' stroke='%23393939' stroke-width='2' stroke-linejoin='round' stroke-dasharray='4 8'/%3E%3C/svg%3E");
	}
}
