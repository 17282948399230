@tailwind utilities;

@layer utilities {
  // TEXT

  .text-high-on-dark {
    color: #fff;
  }

  .text-medium {
    color: #7e8ca0;
  }

  .text-low {
    color: #c5d0dd;
  }

  .text-default-neutral-1 {
    color: #303d50;
  }

  .text-default-neutral-2 {
    color: #e4eaf1;
  }

  .text-default-neutral-3 {
    color: #7e8ca0;
  }

  .text-default-primary-1 {
    color: #b1e1be;
  }

  .text-default-primary-2 {
    color: #62c37d;
  }

  .text-default-secondary-1 {
    color: #73aeff;
  }

  .text-default-secondary-2 {
    color: #006cff;
  }

  .text-default-tertiary-1 {
    color: #ff7373;
  }

  .text-default-tertiary-2 {
    color: #f51818;
  }

  .text-active-neutral-1 {
    color: #5c6a80;
  }

  .text-active-neutral-2 {
    color: #d2dae5;
  }

  .text-active-neutral-3 {
    color: #5c6a80;
  }

  .text-active-primary-1 {
    color: #62c37d;
  }

  .text-active-primary-2 {
    color: #4e9c64;
  }

  .text-active-secondary-1 {
    color: #006cff;
  }

  .text-active-secondary-2 {
    color: #084599;
  }

  .text-active-tertiary-1 {
    color: #f51818;
  }

  .text-active-tertiary-2 {
    color: #9e1313;
  }

  .text-chart-green-1 {
    color: #41b1a6;
  }

  .text-chart-blue-1 {
    color: #4ca6d9;
  }

  .text-chart-red-1 {
    color: #f16a6a;
  }

  .text-chart-orange-1 {
    color: #f28118;
  }

  .text-chart-yellow-1 {
    color: #f1b418;
  }

  .text-default-success-1 {
    color: #62c37d;
  }

  .text-active-success-1 {
    color: #4e9c64;
  }

  .text-default-error-1 {
    color: #f51818;
  }

  .text-active-error-1 {
    color: #9e1313;
  }

  .text-high-on-light {
    color: #303d50;
  }

  .text-chart-purple-1 {
    color: #b26ee5;
  }

  .text-default-quaternary-1 {
    color: #fae697;
  }

  .text-active-quaternary-1 {
    color: #f5c918;
  }

  .text-default-quaternary-2 {
    color: #f5c918;
  }

  .text-active-quaternary-2 {
    color: #dbaf00;
  }

  .text-chart-neutral-1 {
    color: #7e8ca0;
  }

  // BG

  .bg-surface-1 {
    background-color: #fff;
  }

  .bg-surface-2 {
    background-color: #ffffffb2;
  }

  .bg-surface-3 {
    background-color: #f8fafc;
  }

  .bg-surface-4 {
    background-color: #f1f5f9;
  }

  .bg-surface-5 {
    background-color: #5c6a80;
  }

  .bg-surface-6 {
    background-color: #303d50;
  }

  .bg-blur-high {
    background-color: #ffffff80;
  }

  .bg-blur-medium {
    background-color: #fff3;
  }

  .bg-blur-low {
    background-color: #ffffff0d;
  }

  .bg-default-neutral-1 {
    background-color: #303d50;
  }

  .bg-default-neutral-2 {
    background-color: #f1f5f9;
  }

  .bg-default-neutral-3 {
    background-color: #7a9ccc0d;
  }

  .bg-default-neutral-4 {
    background-color: #f1f5f9;
  }

  .bg-default-primary-1 {
    background-color: #e8f6ec;
  }

  .bg-default-primary-2 {
    background-color: #62c37d;
  }

  .bg-default-secondary-1 {
    background-color: #e5f0ff;
  }

  .bg-default-secondary-2 {
    background-color: #006cff;
  }

  .bg-default-tertiary-1 {
    background-color: #fae6e6;
  }

  .bg-default-tertiary-2 {
    background-color: #f51818;
  }

  .bg-active-neutral-1 {
    background-color: #5c6a80;
  }

  .bg-active-neutral-2 {
    background-color: #e4eaf1;
  }

  .bg-active-neutral-3 {
    background-color: #a3b8cc26;
  }

  .bg-active-neutral-4 {
    background-color: #e4eaf1;
  }

  .bg-active-primary-1 {
    background-color: #d7f0de;
  }

  .bg-active-primary-2 {
    background-color: #4e9c64;
  }

  .bg-primary-1 {
    background-color: #f3fbf5;
  }

  .bg-active-secondary-1 {
    background-color: #d9e9ff;
  }

  .bg-active-secondary-2 {
    background-color: #084599;
  }

  .bg-secondary-1 {
    background-color: #f2f7ff;
  }

  .bg-tertiary-1 {
    background-color: #fcf5f5;
  }

  .bg-active-tertiary-1 {
    background-color: #fadcdc;
  }

  .bg-active-tertiary-2 {
    background-color: #9e1313;
  }

  .bg-chart-green-1 {
    background-color: #41b1a6;
  }

  .bg-chart-green-2 {
    background-color: #71c5bc;
  }

  .bg-chart-green-3 {
    background-color: #41b1a659;
  }

  .bg-chart-green-4 {
    background-color: #cfebe8;
  }

  .bg-chart-green-5 {
    background-color: #41b1a60d;
  }

  .bg-chart-blue-1 {
    background-color: #4ca6d9;
  }

  .bg-chart-blue-2 {
    background-color: #79bce3;
  }

  .bg-chart-blue-4 {
    background-color: #d2e8f5;
  }

  .bg-chart-blue-3 {
    background-color: #4ca6d959;
  }

  .bg-chart-blue-5 {
    background-color: #4ca6d90d;
  }

  .bg-chart-red-1 {
    background-color: #f16a6a;
  }

  .bg-chart-red-2 {
    background-color: #f58f8f;
  }

  .bg-chart-red-3 {
    background-color: #f16a6a59;
  }

  .bg-chart-red-4 {
    background-color: #fbd9d9;
  }

  .bg-chart-red-5 {
    background-color: #f16a6a0d;
  }

  .bg-chart-orange-1 {
    background-color: #f28118;
  }

  .bg-chart-orange-2 {
    background-color: #f5a152;
  }

  .bg-chart-orange-4 {
    background-color: #fbdfc5;
  }

  .bg-chart-orange-3 {
    background-color: #f2811859;
  }

  .bg-chart-orange-5 {
    background-color: #f281180d;
  }

  .bg-chart-yellow-1 {
    background-color: #f1b418;
  }

  .bg-chart-yellow-2 {
    background-color: #f5c752;
  }

  .bg-chart-yellow-4 {
    background-color: #fbecc5;
  }

  .bg-chart-yellow-3 {
    background-color: #f1b41859;
  }

  .bg-chart-yellow-5 {
    background-color: #f1b4180d;
  }

  .bg-default-success-1 {
    background-color: #e8f6ec;
  }

  .bg-default-success-2 {
    background-color: #62c37d;
  }

  .bg-active-success-1 {
    background-color: #d7f0de;
  }

  .bg-active-success-2 {
    background-color: #4e9c64;
  }

  .bg-default-error-1 {
    background-color: #fae6e6;
  }

  .bg-default-error-2 {
    background-color: #f51818;
  }

  .bg-active-error-1 {
    background-color: #fadcdc;
  }

  .bg-active-error-2 {
    background-color: #9e1313;
  }

  .bg-chart-purple-1 {
    background-color: #b26ee5;
  }

  .bg-chart-purple-2 {
    background-color: #c592ec;
  }

  .bg-chart-purple-3 {
    background-color: #b26ee559;
  }

  .bg-chart-purple-4 {
    background-color: #ebdaf8;
  }

  .bg-chart-purple-5 {
    background-color: #b26ee50d;
  }

  .bg-default-quaternary-1 {
    background-color: #fef7dd;
  }

  .bg-default-quaternary-2 {
    background-color: #f5c918;
  }

  .bg-active-quaternary-1 {
    background-color: #fcf1c5;
  }

  .bg-active-quaternary-2 {
    background-color: #dbaf00;
  }

  .bg-quaternary-1 {
    background-color: #fefcf3;
  }

  .bg-chart-neutral-1 {
    background-color: #7e8ca0;
  }

  .bg-chart-neutral-2 {
    background-color: #a3b0c2;
  }

  .bg-chart-neutral-3 {
    background-color: #7d95b259;
  }

  .bg-chart-neutral-4 {
    background-color: #e4eaf1;
  }

  .bg-chart-neutral-5 {
    background-color: #7a9ccc0d;
  }

  // BORDER

  .border-default-neutral-1 {
    border-color: #e4eaf1;
  }

  .border-default-neutral-2 {
    border-color: #a3b0c2;
  }

  .border-chart-neutral-2 {
    border-color: #d2dae5;
  }

  .border-chart-neutral-1 {
    border-color: #7e8ca0;
  }

  .border-default-primary-1 {
    border-color: #62c37d;
  }

  .border-default-secondary-1 {
    border-color: #006cff;
  }

  .border-default-tertiary-1 {
    border-color: #f51818;
  }

  .border-chart-green-1 {
    border-color: #41b1a6;
  }

  .border-chart-green-2 {
    border-color: #71c5bc;
  }

  .border-chart-blue-1 {
    border-color: #4ca6d9;
  }

  .border-chart-blue-2 {
    border-color: #79bce3;
  }

  .border-chart-red-1 {
    border-color: #f16a6a;
  }

  .border-chart-red-2 {
    border-color: #f58f8f;
  }

  .border-chart-orange-1 {
    border-color: #f28118;
  }

  .border-chart-orange-2 {
    border-color: #f5a152;
  }

  .border-chart-yellow-1 {
    border-color: #f1b418;
  }

  .border-chart-yellow-2 {
    border-color: #f5c752;
  }

  .border-default-success-1 {
    border-color: #62c37d;
  }

  .border-default-error-1 {
    border-color: #f51818;
  }

  .border-active-neutral-1 {
    border-color: #d2dae5;
  }

  .border-active-neutral-2 {
    border-color: #7e8ca0;
  }

  .border-active-neutral-3 {
    border-color: #ccc;
  }

  .border-active-neutral-4 {
    border-color: #393939;
  }

  .border-active-primary-1 {
    border-color: #4e9c64;
  }

  .border-active-secondary-1 {
    border-color: #084599;
  }

  .border-active-tertiary-1 {
    border-color: #99100f;
  }

  .border-active-success-1 {
    border-color: #4e9c64;
  }

  .border-active-error-1 {
    border-color: #9e1313;
  }

  .border-chart-purple-1 {
    border-color: #b26ee5;
  }

  .border-chart-purple-2 {
    border-color: #c592ec;
  }

  .border-default-quaternary-1 {
    border-color: #f5c918;
  }

  .border-active-quaternary-1 {
    border-color: #dbaf00;
  }
}
