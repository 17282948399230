@layer utilities {
	.bg-gradient-default-primary-2 {
		background: linear-gradient(180deg, rgba(98, 195, 125, 0) 0%, #62c37d 100%);
	}

	.bg-gradient-transparent-black {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000000 100%);
	}

  .bg-yellow-gradient {
    background: linear-gradient(90deg, rgba(241, 180, 24, 0.1) 0%, rgba(241, 180, 24, 0) 100%);
  }

  .bg-gray-gradient {
    background: linear-gradient(90deg, rgba(109, 111, 114,0.1) 0%, rgba(109, 111, 114, 0) 100%);
  }

  .bg-green-gradient {
    background: linear-gradient(90deg, rgba(65, 177, 166, 0.1) 0%, rgba(65, 177, 166, 0) 100%);
  }

  .bg-red-gradient {
    background: linear-gradient(90deg, rgba(241, 109, 106, 0.1) 0%, rgba(241, 109, 106, 0) 100%);
  }
}
