@mixin home-material-overrides() {
    app-home-tab-content .mat-tab-body.mat-tab-body-active, app-money-flow-sector-dialog .mat-tab-body.mat-tab-body-active {
        overflow-y: hidden;
        .mat-tab-body-content {
            overflow: hidden;
        }
    }

    app-home-tab-content, app-money-flow-sector-dialog {
        .money-flow-open-interest-table {
            min-width: 840px !important;

            &-head {
                min-width: 840px !important;
            }
        }

        .money-flow-open-interest-table-row {
            min-width: 840px !important;
        }

        .money-flow-open-interest-table-body {
            min-width: 840px !important;
        }

        .money-flow-open-interest-table-column {
            width: 120px !important;
        }

        .money-flow-open-interest-table-head {
            padding: 20px 10px !important;

            &-left, &-right {
                gap: 2px;
            }
            
            .money-flow-open-interest-table-column {
                width: auto !important;
            }
        }

        .money-flow-growth-bets-diagram-months {
            max-width: 490px !important;
            
            @media (max-width: 1100px) {
                max-width: 705px !important;
            }

            @media (max-width: 950px) {
                max-width: calc(78vw - 60px) !important;
            }

            @media (max-width: 650px) {
                max-width: calc(78vw - 85px) !important;
            }

            @media (max-width: 500px) {
                max-width: calc(70vw - 85px) !important;
            }
        }

        .money-flow-key-levels-diagram-data-container {
            width: 95% !important;
        }
    }
}