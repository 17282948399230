@import '../fonts';

@mixin forms-material-overrides() {
  app-form-layout {
    mat-form-field {
      .mat-form-field-outline {
        display: none !important;
      }

      .mat-form-field-wrapper {
        height: 40px;
      }

      &.mat-form-field-should-float .mat-form-field-label {
        display: none !important;
      }

      .mat-form-field-infix {
        border-top-width: 9px;
      }
    }
  }
}
