.scrollbar-x::-webkit-scrollbar {
  @apply h-1 rounded bg-default-neutral-2;
}

.scrollbar-y::-webkit-scrollbar {
  @apply w-1 rounded bg-default-neutral-2;
}

.scrollbar-x::-webkit-scrollbar-thumb,
.scrollbar-y::-webkit-scrollbar-thumb {
  @apply rounded bg-active-neutral-2;
}

@-moz-document url-prefix() {
  .scrollbar-x {
    scrollbar-width: thin;
    scrollbar-color: #e4eaf1 #f1f5f9;
  }

  .scrollbar-y {
    scrollbar-width: thin;
    scrollbar-color: #e4eaf1 #f1f5f9;
  }
}
