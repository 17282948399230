@import '../fonts';

@mixin portfolio-material-overrides() {
  .portfolio-stock-tooltip {
    .red  {
      color: #EB5757;
      font-size: 12px;
      font-weight: 600;
    }

    .grey {
      color: #00000059;
      font-size: 12px;
      font-weight: 600;
    }
  }

  app-portfolio-congratulations-dialog {
    .header-search-results {
      margin-top: -20rem !important;
      margin-left: 0 !important;
    }
  }
}
