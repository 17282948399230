@font-face {
  font-family: 'Averta CY';
  src:
    url('../fonts/AvertaCyrRegular.woff2') format('woff2'),
    url('../fonts/AvertaCyrRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Averta CY';
  src:
    url('../fonts/AvertaCyrBold.woff2') format('woff2'),
    url('../fonts/AvertaCyrBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Averta CY';
  src:
    url('../fonts/AvertaCyrLight.woff2') format('woff2'),
    url('../fonts/AvertaCyrLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Averta CY';
  src:
    url('../fonts/AvertaCyrSemiBold.woff2') format('woff2'),
    url('../fonts/AvertaCyrSemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Averta CY';
  src:
    url('../fonts/AvertaCyrSemiBoldItalic.woff2') format('woff2'),
    url('../fonts/AvertaCyrSemiBoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: auto;
}
