@import '/src/assets/scss/variables.scss';

@mixin overview-diagram-material-overrides() {
  app-overview-diagram {
    svg  {
      @media (max-width: 900px) {
        width: 70%;
      }
    }
  }
}
