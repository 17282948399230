@import '../fonts';

@mixin auth-form-material-overrides() {
    .auth-form {
        &-text-field {
            position: relative;
            margin-top: 10px;

            mat-form-field {
                width: 100%;
                background-color: #74748014;
                color: #8A8A8E;
                border-radius: 8px;
                border: none;
                font-size: 12px;
                font-weight: 300;

                .mat-form-field-wrapper {
                    height: 45px;
                }

                .mat-form-field-flex {
                    padding: 0 15px;
                }

                mat-label {
                    display: flex;
                    align-items: center;
                    font-size: 17px;
                }
            }
        }

        &-error-message {
            color: rgb(190, 53, 53);
            padding: 10px;
            font-size: 18px;
            text-align: center;
        }


        &-button {
            width: 100%;
            height: 50px;
            background-color: #62C37D;
            color: #fff;
            margin-top: 10px;
            border: none;
            border-radius: 8px;
            font-size: 15px;
        }

        &-link {
            font-size: 14px;
            font-weight: 600;
            color: #2F80ED;
            cursor: pointer;
            
            &:hover {
                text-decoration: underline;
            }
        }

        &-social-button {
            display: flex;
            padding: 10px 0;
            border: 1px solid #33333380;
            border-radius: 5px;
            justify-content: center;
            gap: 10px;
            align-items: center;
            margin-top: 10px;
            cursor: pointer;
        }

        &-divider {
            margin-top: 25px;
            margin-bottom: 25px;
            position: relative;
            &-line {
              height: 1px;
              width: 100%;
              background-color: #EAEAEA;
            }
          
            &-text {
              position: absolute;
              left: 50%;
              width: 40px;
              border-radius: 3px;
              background-color: #fff;
              text-align: center;
              transform: translate(-50%, calc(-50% - 2px));
              font-size: 16px;
            }
          }
    }

    .auth-form-dialog {

        @media (max-width: $breakpoint-max-sm) {
            max-width: unset !important;
            width: 100%;
        }
        
        mat-dialog-container {
            overflow: unset;
        }
    }
}