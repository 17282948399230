@import '../fonts';
@import '/src/assets/scss/variables.scss';

@mixin tv-diagram-material-overrides() {
  .trading-view-container {
    max-width: 94vw;
    height: 100%;
    .tradingview-widget {
      width: 100% !important;
      height: 100% !important;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}
