/* Variables */

$mobile-trigger: 768px;
$mobile-menu: 60px;
$mobile-header-logo-height: 62px;
$mobile-side-menu-height: 62px;
$header-height: $mobile-header-logo-height + $mobile-side-menu-height;

// Grid breakpoints
// min-width
$breakpoint-min-sm: 576px;
$breakpoint-min-md: 768px;
$breakpoint-min-lg: 992px;
$breakpoint-min-xl: 1200px;
// max-width
$breakpoint-max-xs: 575.98px;
$breakpoint-max-sm: 767.98px;
$breakpoint-max-md: 991.98px;
$breakpoint-max-lg: 1199.98px;
$breakpoint-max-xl: 1299.98px;
//standart-screens
$xl: 1280px;
$lg: 1024px;
$md-max: 1023.98px;

// colors
$red: #EB5757;
$green: #62C37D;

%vertical-scrollbar {
    &::-webkit-scrollbar {
        width: 5px;
        height: auto;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
}

%horizontal-scrollbar {
    &::-webkit-scrollbar {
        width: auto;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
}
