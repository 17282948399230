.animate-opacity {
  animation: ease-in-out 4s infinite;
  animation-name: opacity;
}

@keyframes opacity {
  0% {
    @apply opacity-100;
  }
  12% {
    @apply opacity-0;
  }
  50% {
    @apply opacity-0;
  }
  77% {
    @apply opacity-100;
  }
}
