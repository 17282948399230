.kw-form-control-select {
  .kw-form-control-icon {
    transform: rotate(180deg);
    transition-duration: 200ms;

    &-disabled {
      opacity: 0.2;
    }
  }

  &:focus-within {
    .kw-form-control-icon {
      transform: rotate(0);
    }
  }

  &.disabled {
    .kw-form-control-floating-label {
      @apply text-low;
    }
  }

  .kw-form-control-floating-label {
    @apply text-medium;
  }

  .kw-form-control-input {
    padding-right: 0 !important;
  }

  &:has(.kw-form-control-input:focus),
  &:has(.kw-form-control-input:not(:placeholder-shown)) {
    .kw-form-control-floating-label {
      @apply text-interface-control-1-regular;
      top: 12px;
    }

    .kw-form-control-input {
      padding-top: 18px;
      padding-bottom: 6px;
    }
  }

  .active {
    font-size: 12px !important;
    top: 0 !important;
  }
}
