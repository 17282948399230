@import '/src/assets/scss/variables.scss';

@mixin footer-material-overrides() {
    app-footer {
        app-onboarding-hint {
            @media (max-width: $breakpoint-max-sm) {
                flex-direction: column-reverse !important;

                .onboarding-hint-arrow {
                    position: relative;
                    transform: rotate(180deg);
                }
            }
        }

        .footer-social-media {
            max-width: 280px;
            flex-wrap: wrap;
            gap: 10px;
            color: #A9AEC3;
        }
    }
}