@import '/src/assets/scss/variables.scss';

@mixin mat-dialog-material-overrides() {
  mat-dialog-container.mat-dialog-container {
    border-radius: 10px !important;
    overflow: unset;
    padding: 0;

    @media (max-width: $breakpoint-max-sm) {
      box-shadow: none;
    }

    @media (min-width: $breakpoint-min-md) {

      // Key Events Ribbon Details News Modal Design

      &:not(#events-timeline-dialog-news) {
        max-height: 90vh !important;
      }
    }
  }

  .cdk-overlay-pane {
    @media (max-width: $breakpoint-max-sm) {
      max-width: unset !important;
      width: 100%;
    }
  }
}

// Key Events Ribbon Details News Modal Design

body:has(#events-timeline-dialog-news) {
  .cdk-global-overlay-wrapper {
    overflow: auto;
    pointer-events: auto;

    @apply scrollbar-hide;
  }
}
