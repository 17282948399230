@import './assets/scss/fonts';
@import './assets/scss/material-overrides/material-overrides';
@import './assets/scss/shadow';

@include material-overrides();

body {
  font-family: 'Averta CY' !important;
  color: #333333 !important;
  overflow-x: hidden;
}

html, body, head {
  margin: 0 !important;
  padding: 0 !important;
}

head {
  height: 0 !important;
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
