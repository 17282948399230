@import '../fonts';
@import '/src/assets/scss/variables.scss';

@mixin sector-trends-material-overrides() {
  app-sector-trends {
    mat-tab-header {
      border-bottom: none !important;
      overflow: unset !important;

      .mat-tab-label-container {
        overflow: unset !important;
      }

      .mat-tab-labels {
        display: inline-flex;
        border: 1px solid #EAEAEA;
        padding: 4px;
        border-radius: 5px;
        font-size: 13px;
        line-height: 20px;
        color: #000000;
        background-color: #F3F8FE;

        @media screen and (max-width: $mobile-trigger) {
          width: 100%;
          justify-content: center;
        }
      }

      mat-ink-bar {
        display: none;
      }

      .mat-ripple {
        overflow: unset !important;
        border-radius: 5px;
        opacity: 1;

        &.mat-tab-label-active {
          background-color: #62C37D;
          color: #fff;
        }

        &-element {
          display: none !important;
        }

        @media screen and (max-width: $mobile-trigger) {
          width: 50%;
        }
      }
    }

    .mat-tab-body-content {
      overflow: hidden;
    }
  }

  app-monetary-conditions {
    .mat-expansion-panel-content {
      display: none;

      @media (max-width: $mobile-trigger) {
        display: flex;
      }
    }
  }

  .key-financial-dialog {
    .mat-dialog-container {
      padding: 0;
    }

    @media screen and (min-width: $mobile-trigger) {
      box-shadow: none !important;
      width: 100vw;
      border-radius: none !important;
      height: calc(100vh - $header-height);
    }
  }

  .monetary-conditions-bottom-left {
    mat-expansion-panel-header {
      padding-right: 0 !important;
    }
  }
}
