@layer utilities {
	.shadow-shadow-badge-shadow {
		box-shadow: 0 1px 4px 0 rgba(0,0,0,0.25);
	}

	.shadow-shadow-block-shadow {
		box-shadow: 1px 4px 15px 0 rgba(0,0,0,0.1);
	}

	.shadow-shadow-popup-sm-shadow {
		box-shadow: 1px 4px 15px 0 rgba(0,0,0,0.1);
	}

	.shadow-shadow-popup-lg-shadow {
		box-shadow: 4px 4px 30px 0 rgba(0,0,0,0.1);
	}

	.shadow-shadow-modal-shadow {
		box-shadow: 1px 10px 30px 10px rgba(0,0,0,0.3);
	}

  .shadow-shadow-landing-papers-cover {
    box-shadow: 96px 155px 51px 0 rgba(0, 0, 0, 0.00), 61px 99px 47px 0 rgba(0, 0, 0, 0.02), 4px 6px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 0 rgba(0, 0, 0, 0.12);
  }

  .shadow-shadow-landing-papers-cover-bg {
    box-shadow: 4px 6px 16px 0 rgba(0,0,0,0.10);
  }
}
