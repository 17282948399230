.dropdown-shadow {
  box-shadow: 4px 4px 30px 0 rgba(0, 0, 0, 0.10);
}

.badge-shadow {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
}

.locked-shadow {
  box-shadow: 1px 4px 15px 0 rgba(0, 0, 0, 0.10);
}

.badge-shadow-svg {
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.15));
}
